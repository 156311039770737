const globalConstants = {
    appName: 'Nabin Portfolio',
    appDesc: "👋 Hi! I'm Nabin, a passionate software engineer. Let's connect and explore the world of coding together! 💻🚀",
    title: 'Nabin Kawan',
    favIcon: 'favIcon',
    twitterHandle: '',
    titleImg: 'titleImg',
    socialPreview: {
        url: `https://www.kawannabin.com.np/`,
        title: 'Nabin Kawan',
        desc: "👋 Hi! I'm Nabin, a passionate software engineer. Let's connect and explore the world of coding together! 💻🚀",
        image: `/images/thumbnail.png`
    },
    consoleWarningTitle: `%cStop!`,
    consoleWarningDescription: `%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature, it is a scam and will give them access to your sensitive information.`,
    imageTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml']
};

export default globalConstants;
